import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";

import Header from "./header";
import "./layout.css";

const Layout = ({ children, maxWidth }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: "0 40px",
            maxWidth: 692,
            padding: "2.9rem 1.45rem",
            paddingTop: 0
          }}
        >
          <main>{children}</main>
          <footer>
            <div className="flex-row">
              <p>That's all for now folks</p>
              <Link to="/" activeStyle={{ display: "none" }}>
                Back to Home
              </Link>
            </div>
            <p className="gray">v1.0 © {new Date().getFullYear()}</p>
          </footer>
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

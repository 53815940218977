import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import styles from "./header.module.scss";

const Header = ({ siteTitle }) => (
  <header className={styles.header}>
    <Link to="/" activeStyle={{ color: "black" }} className={styles.mainLink}>
      {siteTitle}
    </Link>
    <a href="https://twitter.com/ben_ald">Twitter</a>
    <a href="https://www.linkedin.com/in/benalderfer/">LinkedIn</a>
    <a href="https://dribbble.com/balderfer">Dribbble</a>
    <a href="mailto:ben.alderfer@gmail.com">Email</a>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ""
};

export default Header;
